import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import VueI18n from 'vue-i18n';
import _first from 'lodash/first';
import messages from '@/localization/Messages';

Vue.config.productionTip = false;
Vue.use(VueI18n);

const topNavigatorLanguage = _first(window.navigator.languages);
const locale =
  topNavigatorLanguage !== null && topNavigatorLanguage !== undefined
    ? topNavigatorLanguage.substr(0, topNavigatorLanguage.indexOf('-'))
    : 'en';
const i18n = new VueI18n({
  locale,
  messages,
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
