import { LocaleMessageObject } from 'vue-i18n';

const english: LocaleMessageObject = {
  'app.title': 'RPC Tooling',

  home: 'Home',
  'was.not.able.to.be.loaded': ' was not able to be loaded',

  'page.title.help': 'Help',
  'page.title.about': 'About',

  'title.pretty.print': 'Pretty Print',
  'title.minify': 'Minify',
  'title.maximize': 'Maximize',
  'title.restore': 'Restore',
  'title.help': 'Help',
  'title.about': 'About',

  'editor.title.default': 'Editor',
  'editor.title.input': 'Input',
  'editor.title.result': 'Result',

  'button.clear': 'Clear',
  'button.dismiss': 'Dismiss',

  'json.pathing': 'JSON Pathing',
  'json.diff': 'JSON Diff',
  jsonata: 'JSONata',
};

export default english;
