

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { KeyedErrorMessage } from '@/error/ErrorMessage';

@Component
export default class ErrorMessages extends Vue {
  @Prop({ default: [] }) public errorMessages: KeyedErrorMessage[];
}
