








import { Component, Vue } from 'vue-property-decorator';
import ErrorMessage, { KeyedErrorMessage } from '@/error/ErrorMessage';
import ErrorMessages from '@/error/component/ErrorMessages.vue';
import Header from '@/header/component/Header.vue';
import WindowDimensions from '@/store/WindowDimensions';

@Component({
  components: {
    ErrorMessages,
    Header,
  },
})
export default class App extends Vue {
  private element: HTMLElement | null = null;
  private appHeader: HTMLElement | null = null;

  private mounted() {
    this.element = this.$el as HTMLElement;
    this.appHeader = this.$refs.appHeader as HTMLElement;
    this.element.addEventListener('resize', this.appResized);
    window.addEventListener('resize', this.appResized);

    this.appResized();
  }

  private appResized() {
    this.$store.commit('updateWindowDimensions', this.calculateDimensions());
  }

  private calculateDimensions(): WindowDimensions {
    return {
      height: this.mainHeight(),
      width: this.mainWidth(),
      top: this.mainTop(),
      left: this.mainLeft(),
    };
  }

  private mainTop(): number {
    return this.element?.offsetTop || 0;
  }

  private mainLeft(): number {
    return this.element?.offsetLeft || 0;
  }

  private mainWidth(): number {
    return this.element?.offsetWidth || 0;
  }

  private mainHeight(): number {
    const offsetHeight = this.element?.offsetHeight || 0;

    return offsetHeight - 44;
  }

  get errorMessages(): ErrorMessage[] {
    return this.$store.state.errors;
  }

  private dismissErrorMessage(errorMessage: KeyedErrorMessage) {
    this.$store.commit('removeErrorMessage', errorMessage);
  }

  private clearErrorMessages() {
    this.$store.commit('clearErrorMessages');
  }
}
