







import { Component, Vue } from 'vue-property-decorator';
import ModeSelect from '@/header/component/ModeSelect.vue';

@Component({
  components: { ModeSelect },
})
export default class Header extends Vue {
  get jsonPath(): string {
    return this.$store.state.item.jsonPath;
  }

  set jsonPath(value: string) {
    this.$store.commit('updatePath', value);
  }
}
