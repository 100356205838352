export default interface ErrorMessage {
  readonly errorMessageHeader: string;
  readonly errorMessageBody: string;
}

export class KeyedErrorMessage implements ErrorMessage {
  readonly key: number;
  readonly errorMessageBody: string;
  readonly errorMessageHeader: string;

  constructor(key: number, errorMessage: ErrorMessage) {
    this.key = key;
    this.errorMessageBody = errorMessage.errorMessageBody;
    this.errorMessageHeader = errorMessage.errorMessageHeader;
  }
}
