import Vue from 'vue';
import Router from 'vue-router';
import { JSON_PATHING } from '@/store/EditorState';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/home/view/Home.vue'),
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import(/* webpackChunkName: "notFound" */ '@/error/view/NotFound.vue'),
    },
    {
      path: JSON_PATHING.path,
      name: JSON_PATHING.name,
      component: () => import(/* webpackChunkName: "pathingTool" */ '@/pathing/view/PathingTool.vue'),
    },
  ],
});
