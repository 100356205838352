// discriminated union for what editor is being used
interface Tooling {
  value: String;
  name: String;
  path: String;
}

interface Home extends Tooling {
  value: 'home';
  name: 'home';
  path: '/';
}
interface JsonPathing extends Tooling {
  value: 'json.pathing';
  name: 'jsonPathing';
  path: '/json/pathing';
}

interface JsonDiff extends Tooling {
  value: 'json.diff';
  name: 'jsonDiff';
  path: '/json/diff';
}

interface Jsonata extends Tooling {
  value: 'jsonata';
  name: 'jsonata';
  path: '/json/ata';
}

export type Tool = Home | JsonPathing | JsonDiff | Jsonata;
export const HOME: Home = { value: 'home', name: 'home', path: '/' };
export const JSON_PATHING: JsonPathing = { value: 'json.pathing', name: 'jsonPathing', path: '/json/pathing' };
export const JSON_DIFFING: JsonDiff = { value: 'json.diff', name: 'jsonDiff', path: '/json/diff' };
export const JSONATA: Jsonata = { value: 'jsonata', name: 'jsonata', path: '/json/ata' };
export const tools: Tool[] = [HOME, JSON_PATHING, JSON_DIFFING, JSONATA];
