import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { RootState } from '@/store/RootState';
import { HOME, Tool } from '@/store/EditorState';
import ErrorMessage, { KeyedErrorMessage } from '@/error/ErrorMessage';
import WindowDimensions from '@/store/WindowDimensions';

Vue.use(Vuex);

export default new Store<RootState>({
  state: {
    errors: [],
    errorIndex: 0,
    activeMode: HOME,
    windowDimensions: {
      height: 0,
      width: 0,
      top: 0,
      left: 0,
    },
  },
  mutations: {
    reportError: (state: RootState, message: ErrorMessage): RootState => {
      state.errorIndex++;

      state.errors.push(new KeyedErrorMessage(state.errorIndex, message));

      return state;
    },
    updateWindowDimensions: (state: RootState, dimensions: WindowDimensions): RootState => {
      state.windowDimensions = { ...dimensions };

      return state;
    },
    removeErrorMessage: (state: RootState, payload: KeyedErrorMessage): RootState => {
      state.errors = state.errors.filter((em) => em.key !== payload.key);

      return state;
    },
    clearErrorMessages: (state: RootState): RootState => {
      state.errors.splice(0, state.errors.length);

      return state;
    },
    changeActiveMode: (state: RootState, payload: Tool): RootState => {
      state.activeMode = payload;

      return state;
    },
  },
});
