




































import { Component, Vue } from 'vue-property-decorator';
import { Tool, tools } from '@/store/EditorState';

@Component
export default class ModeSelect extends Vue {
  private chooserOpen: boolean = false;

  private showHideModeChooserMenu() {
    this.chooserOpen = !this.chooserOpen;
  }

  private beforeMount() {
    if (this.$router.currentRoute.fullPath != window.location.pathname) {
      const foundMode = tools.find((value) => value.path == window.location.pathname);

      if (foundMode) {
        this.$store.commit('changeActiveMode', foundMode);
        this.$router.replace({ path: foundMode.path });
      }
    }
  }

  get tools(): Tool[] {
    return tools;
  }

  get selectedMode(): String {
    return this.$store.state.activeMode.value;
  }

  set selectedMode(selectedMode: String) {
    const foundMode = tools.find((value) => value.name === selectedMode);

    if (this.$router.currentRoute.fullPath != foundMode.path) {
      this.$store.commit('changeActiveMode', foundMode);
      this.$router.push({ path: foundMode.path });
    }
  }
}
